// App.js
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import CreateCases from './components/CreateCases';
import KanbanBoard from './components/KanbanBoard';
import HomePage from './components/HomePage';
import "toastify-js/src/toastify.css";
import 'react-datepicker/dist/react-datepicker.css';
import { SessionProvider, useSession } from './services/SessionProvider'; // Import useSession here
import { initializeSession } from './services/api-service';
import Toastify from 'toastify-js';

// Create a component to wrap the session logic
const AppWrapper = () => {
  const { setIsSessionExpired } = useSession();

  const handleSessionExpired = () => {
    setIsSessionExpired(true);
    localStorage.setItem("sessionExpired", "true");
    console.log("Executing handleSessionExpired.");
  
    Toastify({
      text: "Your session has expired. Please log in again.",
      duration: 3000,
      close: true,
      gravity: "top",
      position: "right",
      backgroundColor: "#f44336",
      stopOnFocus: true,
    }).showToast();
  };

  useEffect(() => {
    initializeSession(handleSessionExpired);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/homepage" element={<HomePage />} />
      {/* <Route path="/create-cases" element={<CreateCases />} /> */}
      {/* <Route path="/kanban-board" element={<KanbanBoard />} /> */}
    </Routes>
  );
}

function App() {
  return (
    <SessionProvider>
      <BrowserRouter>
        <AppWrapper /> {/* Move the session logic to this wrapper component */}
      </BrowserRouter>
    </SessionProvider>
  );
}

export default App;
