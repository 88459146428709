import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PdfViewerComponent from './PdfViewerComponent'; // Import your PDF viewer component

const PdfModal = ({ show, handleClose, pdfUrl }) => {
  return (
    <Modal show={show} onHide={handleClose} size="xl" className="custom-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Document</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: '80vh', overflow: 'auto' }}>
        {pdfUrl ? (
          <PdfViewerComponent pdfUrl={pdfUrl} />
        ) : (
          <p>No PDF URL provided.</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PdfModal;
