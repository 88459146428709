import React, { useMemo, useState, useEffect } from "react";
import ColumnContainer from "./ColumnContainer.jsx";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard.jsx";
import {
  updateCaseStatus,
  getStatement,
  getAppeals,
} from "../services/api-service.js";
import Toastify from "toastify-js";
import { useNavigate } from 'react-router-dom';

const defaultCols = [
  {
    id: "assessmentOrderReceived",
    title: "Assessment order received",
  },
  {
    id: "submissionsFromClient",
    title: "Submissions from client",
  },
  {
    id: "workingOnSOFandGOA",
    title: "Working on SOF & GOA",
  },
  {
    id: "workingOnReplies",
    title: "Working on replies",
  },
  {
    id: "readyForITPortal",
    title: "Ready for IT portal",
  },
  {
    id: "repliesReady",
    title: "Replies ready",
  },
  {
    id: "uploadedToITPortal",
    title: "Uploaded to IT portal",
  },
];

function KanbanBoard({ tasks, setTasks, deleteTask }) {
  const [columns] = useState(defaultCols);
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);
  const navigate = useNavigate();

  const [activeTask, setActiveTask] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const setupWebSocket = () => {
    // Initialize WebSocket connection
    const ws = new WebSocket("wss://testinglegalvisum.visumai.in/ws");
  
    // Helper function to check if a string is JSON
    const isJSON = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    };
  
    ws.onmessage = (event) => {
      const message = event.data;
  
      if (isJSON(message)) {
        const messageData = JSON.parse(message);
  
        // Check if the parsed JSON contains the expected properties
        if (messageData.case_id && messageData.case_status?.case_status) {
          const newStatus = messageData.case_status.case_status;
          
          setTasks((prevTasks) =>
            prevTasks.map((task) =>
              task.case_id === messageData.case_id
                ? { ...task, case_status: newStatus }
                : task
            )
          );
  
          // Move the task to its new column based on the updated case status
          setTasks((prevTasks) => {
            const updatedTasks = [...prevTasks];
            const taskIndex = updatedTasks.findIndex((task) => task.case_id === messageData.case_id);
            
            if (taskIndex > -1) {
              const [taskToUpdate] = updatedTasks.splice(taskIndex, 1);
              taskToUpdate.case_status = newStatus;
              updatedTasks.push(taskToUpdate);
            }
  
            return updatedTasks;
          });
        }
      } else {
        // If it's not JSON, log the plain message
        console.log("Received non-JSON message:", message);
      }
    };
  
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };
  
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };
  
    // Return the WebSocket instance to allow further control if needed
    return ws;
  };  

  useEffect(() => {
    setupWebSocket();
  }, []);

  return (
    <div
      className="container d-flex w-100 align-items-center overflow-auto p-0 fs-1"
      style={{ scrollBehavior: "smooth" }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <div className="d-flex gap-3">
          <SortableContext items={columnsId}>
            {columns.map((col) => (
              <ColumnContainer
                key={col.id}
                column={col}
                updateTask={updateTask}
                tasks={tasks.filter((task) => task.case_status === col.id)}
                deleteTask={deleteTask}
              />
            ))}
          </SortableContext>
        </div>

        {createPortal(
          <DragOverlay>
            {activeTask && (
              <TaskCard
                task={activeTask}
                updateTask={updateTask}
                deleteTask={deleteTask}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  );

  function updateTask(updatedTask) {
    setTasks((prevTasks) => {
      const updatedTasks = prevTasks.map((task) => {
        if (task._id === updatedTask._id) {
          return { ...task, ...updatedTask };
        }
        return task;
      });

      return updatedTasks;
    });
  }

  function onDragStart(event) {
    const { active } = event;
    console.log("Dragging started:", active.id);

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
    }
  }

  async function onDragEnd(event) {
    setActiveTask(null);

    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const activeId = active.id;
    const overId = over.id;
    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";
    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveATask && isOverATask) {
      const activeIndex = tasks.findIndex((task) => task._id === activeId);
      const overIndex = tasks.findIndex((task) => task._id === overId);

      if (activeIndex === -1 || overIndex === -1) return;

      const activeTask = tasks[activeIndex];
      const overTask = tasks[overIndex];

      if (overTask.case_status === "readyForITPortal" || overTask.case_status === "repliesReady" || overTask.case_status === "uploadedToITPortal") {
        try {
          const isApproved = await checkApprovalStatus(activeTask);
          if (!isApproved) {
            console.error("Task is not approved for SOF and GOA.");
            Toastify({
              text: "Need admin approval for SOF & GOA before uploading to IT portal.",
              duration: 3000,
              close: true,
              gravity: "top",
              position: "right",
              backgroundColor: "#f44336",
              stopOnFocus: true,
            }).showToast();
            return; 
          }
        } catch (error) {
          console.error("Error checking approval status:", error);
          return; // Exit if there's an error
        }
      }

      if (activeTask.case_status !== overTask.case_status) {
        activeTask.case_status = overTask.case_status;
        try {
          await updateCaseStatus(activeTask.case_id, activeTask.case_status);
        } catch (error) {
          console.error("Failed to update case status:", error);
        }
      }

      setTasks(arrayMove(tasks, activeIndex, overIndex));
    } else if (isActiveATask && isOverAColumn) {
      const activeIndex = tasks.findIndex((task) => task._id === activeId);
      if (activeIndex === -1) return;

      const activeTask = tasks[activeIndex];

      if (overId === "readyForITPortal" || overId === "repliesReady" || overId === "uploadedToITPortal") {
        try {
          const isApproved = await checkApprovalStatus(activeTask);
          if (!isApproved) {
            Toastify({
              text: "Need admin approval for SOF & GOA before uploading to IT portal.",
              duration: 3000,
              close: true,
              gravity: "top",
              position: "right",
              backgroundColor: "#f44336",
              stopOnFocus: true,
            }).showToast();
            return; 
          }
        } catch (error) {
          console.error("Error checking approval status:", error);
          return; // Exit if there's an error
        }
      }

      activeTask.case_status = overId;
      try {
        await updateCaseStatus(activeTask.case_id, activeTask.case_status);
      } catch (error) {
        console.error("Failed to update case status:", error);
      }

      setTasks([...tasks]);
    }
  }

  async function checkApprovalStatus(task) {
    try {
      const sofResponse = await getStatement(task.case_id);
      const goaResponse = await getAppeals(task.case_id);
      return (
        sofResponse.data.approval_status === "approved" &&
        goaResponse.data.approval_status === "approved"
      );
    } catch (error) {
      return false; 
    }
  }

  async function onDragOver(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return; // Same ID case

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";
    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveATask && isOverATask) {
      const activeIndex = tasks.findIndex((t) => t._id === activeId);
      const overIndex = tasks.findIndex((t) => t._id === overId);

      if (activeIndex === -1 || overIndex === -1) {
        console.error("Index not found for active or over ID");
        return;
      }

      const activeTask = tasks[activeIndex];
      const overTask = tasks[overIndex];

      if (overTask.case_status === "readyForITPortal" || overTask.case_status === "repliesReady" || overTask.case_status === "uploadedToITPortal") {
        try {
          const isApproved = await checkApprovalStatus(activeTask);
          if (!isApproved) {
            Toastify({
              text: "Need admin approval for SOF & GOA before uploading to IT portal.",
              duration: 3000,
              close: true,
              gravity: "top",
              position: "right",
              backgroundColor: "#f44336",
              stopOnFocus: true,
            }).showToast();
            return;
          }
        } catch (error) {
          console.error("Error checking approval status:", error);
          return; // Exit if there's an error
        }
      }

      if (activeTask.case_status !== overTask.case_status) {
        activeTask.case_status = overTask.case_status;
        try {
          await updateCaseStatus(activeTask.case_id, activeTask.case_status);
          setTasks(arrayMove(tasks, activeIndex, overIndex));
        } catch (error) {
          console.error("Failed to update case status:", error);
        }
      } else {
        setTasks(arrayMove(tasks, activeIndex, overIndex));
      }
    } else if (isActiveATask && isOverAColumn) {
      const activeIndex = tasks.findIndex((t) => t._id === activeId);

      if (activeIndex === -1) {
        console.error("Active task index not found:", activeId);
        return;
      }

      if (overId === "readyForITPortal" || overId === "repliesReady" || overId === "uploadedToITPortal") {
        try {
          const isApproved = await checkApprovalStatus(activeTask);
          if (!isApproved) {
            // Toastify({
            //   text: "Need admin approval for SOF & GOA before uploading to IT portal.",
            //   duration: 3000,
            //   close: true,
            //   gravity: "top",
            //   position: "right",
            //   backgroundColor: "#f44336",
            //   stopOnFocus: true,
            // }).showToast();
            return;
          }
        } catch (error) {
          console.error("Error checking approval status:", error);
          return; 
        }
      }      

      activeTask.case_status = overId;
      try {
        await updateCaseStatus(activeTask.case_id, activeTask.case_status);
        setTasks([...tasks]);
      } catch (error) {
        console.error("Failed to update case status:", error);
      }
    }
  }
}

export default KanbanBoard;
