// src/api/api-service.js
import axios from 'axios';
import Toastify from "toastify-js";

//const API_BASE_URL = 'https://cors-anywhere.herokuapp.com/http://13.215.203.5';
// const API_BASE_URL = 'http://localhost'; 
const API_BASE_URL = 'https://testinglegalvisum.visumai.in/api';
const API_KEY = '4e3d2b599c76a70f8b31c30a51f42312f0d14a724c1e7c6bcd98154f983c4728'; 
const CLIENT_ID = '6172f93b1fcb571a18ea05f974c5ae83';
const CLIENT_SECRET = "036d4b7bf60025dfe6b71fa76ab9e87782c022c33391a49e044e438591c99d60" 

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'api-key': API_KEY, 
  },
});

export const registerUser = async (userData) => {
  try {
    const response = await apiClient.post('/users/', userData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const login = async (username, password, onSessionExpired) => {
  const formData = new URLSearchParams();
  formData.append('client_id', CLIENT_ID);
  formData.append('client_secret', CLIENT_SECRET);
  formData.append('grant_type', 'password');
  formData.append('username', username);
  formData.append('password', password);
  formData.append('scope', '');

  try {
    const response = await apiClient.post("/token", formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { access_token } = response.data;
    const expirationTime = getTokenExpiryTime(access_token);
    
    // Set token and expiration time in localStorage
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("token_expiration", expirationTime);

    console.log("Token and expiration set:", { access_token, expirationTime });
    startSessionTimer(onSessionExpired);

    return response.data;
  } catch (error) {
    console.error("Login error:", error);
    throw error.response ? error.response.data : error.message;
  }
};

// Function to extract expiration time from token
const getTokenExpiryTime = (token) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000; // Convert to milliseconds
  } catch (error) {
    console.error("Token decoding failed:", error);
    return Date.now(); 
  }
};

// Interval-based timer to check session expiration
export const startSessionTimer = (onSessionExpired) => {
  console.log("Starting session timer...");

  const intervalId = setInterval(() => {
    const expirationTime = parseInt(localStorage.getItem("token_expiration"), 10);

    // Stop interval if there's no valid expiration time in localStorage
    if (!expirationTime || isNaN(expirationTime)) {
      console.log("No valid expiration time found; clearing interval.");
      clearInterval(intervalId);
      return;
    }

    const currentTime = Date.now();
    console.log("Checking expiration:", { currentTime, expirationTime });

    if (currentTime >= expirationTime) {
      console.log("Session has expired, triggering expiration logic.");
      clearInterval(intervalId); // Stop further checks
      localStorage.removeItem("token_expiration");
      localStorage.removeItem("access_token");
      onSessionExpired(); // Call session expiration handler
    }
  }, 1000 * 10); // Check every 10 seconds for faster debugging
};

// Check if there's an active session when the app loads
export const initializeSession = (onSessionExpired) => {
  const token = localStorage.getItem("access_token");
  const expirationTime = localStorage.getItem("token_expiration");

  if (token && expirationTime) {
    const currentTime = new Date().getTime();
    const expiresIn = new Date(expirationTime).getTime() - currentTime;

    if (expiresIn > 0) {
      console.log("Active session found. Starting session timer...");
      startSessionTimer(onSessionExpired, expiresIn);
    } else {
      console.log("Session expired. Clearing session data...");
      clearSession();
      if (window.location.pathname === '/homepage') {
        window.location.replace("/");
      }
    }
  } else {
    console.log("No active session found.");
    if (window.location.pathname === '/homepage') {
      window.location.replace("/");
    }
  }
};

// Clears session data
const clearSession = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("token_expiration");
};



export const createCase = async (formData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.post(
      `/cases/create?token=${token}`, // Include token in query string
      formData
    );
    console.log("Case created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getCasesByUserId = async (navigate) => {
  try {
    const token = localStorage.getItem("access_token");
    // if (!token) {
    //   navigate('/'); 
    //   return;
    // }
    const response = await apiClient.get(`/cases?token=${token}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateCaseStatus = async (caseId, caseStatus) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.put(
      `/cases/update_status/${caseId}?token=${token}`,
      { case_status: caseStatus }
    );
    console.log("Case status updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const logout = async (navigate) => {
  try {
    const token = localStorage.getItem("access_token");
    
    if (!token) {
      console.warn("No access token found. Redirecting to login page.");
      navigate('/'); // Redirect to login page if no token is found
      return;
    }

    const response = await apiClient.post(`/logout?token=${token}`);
    console.log("Logged out successfully:", response.data);
    localStorage.removeItem("access_token"); // Clear token from localStorage
    navigate('/'); // Redirect to login page after successful logout
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateCase = async (caseId, updatedCaseData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.put(
      `/cases/update/${caseId}?token=${token}`,
      updatedCaseData
    );
    console.log("Case updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteCase = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.delete(`/cases/delete/${caseId}`, {
      params: {
        token: token,
      },
    });
    console.log("Case deleted:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getUploadDocuments = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.get(`/casedetails/${caseId}`, {
      params: {
        token: token,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const uploadDocuments = async (caseId, documentName, documentType, files) => {
  const formData = new FormData();
  
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const token = localStorage.getItem("access_token");

  const queryParams = new URLSearchParams({
    case_id: caseId,
    document_name: documentName,
    document_type: documentType,
    token: token
  }).toString();

  try {
    const response = await apiClient.post(
      `/casedetails/create?${queryParams}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    console.log("Documents uploaded successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error("Validation Error:", error.response.data);
    } else {
      console.error("An error occurred:", error.message);
    }
    throw error;
  }
};

export const getStatement = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token");

    const response = await apiClient.get(`/statements/${caseId}`, {
      params: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const createStatement = async (statementData) => {
  try {
    const token = localStorage.getItem("access_token");

    const response = await apiClient.post(`/statements/create?token=${token}`, statementData);

    console.log("Statement created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getTodosByCaseId = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.get(`/todos/${caseId}`, {
      params: {
        token: token,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error("Validation Error:", error.response.data);
    } else {
      console.error("An error occurred:", error.message);
    }
    throw error;
  }
};

export const createTodo = async (todoData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.post(
      `/todos/create?token=${token}`, // Include token in query string
      todoData
    );
    console.log("Todo created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateTodo = async (caseId, todoId, updatedTodoData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.put(
      `/todos/${caseId}/${todoId}?token=${token}`, // Include token in query string
      updatedTodoData
    );
    console.log("Todo updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteTodo = async (caseId, todoId) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.delete(`/todos/${caseId}/${todoId}`, {
      params: {
        token: token,
      },
    });
    console.log("Todo deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getUserDetails = async () => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await apiClient.get('/users/me', {
      params: {
        token: token,
      },
    });
    console.log("User details retrieved successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getAIResponse = async (caseId, prompt) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.post('http://172.30.111.77:9900/get_llm_res', {
      case_id: caseId,
      prompt: prompt,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`, // Attach the token in the Authorization header if needed
      },
    });

    console.log("AI response retrieved successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.post('/invoices/create', invoiceData, {
      params: {
        token: token,
      },
    });

    console.log("Invoice created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateInvoice = async (caseId, invoiceData) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.put(`/invoices/${caseId}`, invoiceData, {
      params: {
        token: token,
      },
    });

    console.log("Invoice updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getInvoice = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.get(`/invoices/${caseId}`, {
      params: {
        token: token,
      },
    });

    console.log("Invoice retrieved successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteInvoice = async (invoiceId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.delete(`/invoices/${invoiceId}`, {
      params: {
        token: token,
      },
    });

    console.log("Invoice deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteStatement = async (caseId, statementId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.delete(`/statements/${caseId}/${statementId}`, {
      params: {
        token: token,
      },
    });

    console.log("Statement deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteCaseDetails = async (caseId, uploadId, documentIds) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.delete(`/casedetails/delete/${caseId}/${uploadId}`, {
      params: {
        token: token,
        document_ids: documentIds, 
      },
    });

    console.log("Case details deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateStatement = async ({ case_id, statement_id, document_content, edited_by, approval_status, edit_date, approval_date }) => {
  try {
    const token = localStorage.getItem("access_token");

    const response = await apiClient.put(
      `/statements/${case_id}/${statement_id}?token=${token}`,
      {
        document_content,
        edited_by,
        approval_status,
        edit_date,
        approval_date
      }
    );

    console.log("Statement updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const createAppeal = async (appealData) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.post('/appeals/create', appealData, {
      params: {
        token: token,
      },
    });

    console.log("Appeal created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getAppeals = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.get(`/appeals/${caseId}`, {
      params: {
        token: token,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateAppealStatement = async ({case_id, appeal_id, document_content, edited_by, approval_status, edit_date, approval_date}) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
console.log(appeal_id, "appeal ID")
    const response = await apiClient.put(
      `/appeals/${case_id}/${appeal_id}?token=${token}`,
      {
        document_content,
        edited_by,
        approval_status,
        edit_date,
        approval_date
      }
    );
    console.log("Appeal statement updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getNotes = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.get(`/notes/${caseId}`, {
      params: {
        token: token,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const createNote = async (payload) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.post('/notes/create', payload, {
      params: {
        token: token,
      },
    });

    console.log("Note created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateNote = async (caseId, payload) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.put(
      `/notes/update/${caseId}`,
      payload, // Pass payload directly here
      {
        params: {
          token: token,
        },
      }
    );

    console.log("Note updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};


export const deleteNote = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.delete(`/notes/delete/${caseId}`, {
      params: {
        token: token,
      },
    });

    console.log("Note deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.get('/users/', {
      params: {
        token: token, // Pass the token as a query parameter
      },
    });

    console.log("Users fetched successfully:", response.data);
    return response.data; // Return the users data
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data.detail); // Log validation errors
    } else {
      console.error('An error occurred:', error.message); // Log any other errors
    }
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const updateUser = async (user_id, userData) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.put(`/users/update/${user_id}`, userData, {
      params: {
        token: token, // Pass the token as a query parameter
      },
    });

    console.log("User updated successfully:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error("Validation Error Details:", error.response.data.detail); // Log validation errors
    } else {
      console.error("An error occurred:", error.message); // Log any other errors
    }
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const deleteUser = async (userId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage
    const response = await apiClient.delete(`/users/delete/${userId}`, {
      params: {
        token: token, // Pass the token as a query parameter
      },
    });

    console.log("User deleted successfully:", response.data);
    return response.data; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error("Validation Error Details:", error.response.data.detail); // Log validation errors
    } else {
      console.error("An error occurred:", error.message); // Log any other errors
    }
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const getBillingEntries = async (caseId, date) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.get('/billing/', {
      params: {
        case_id: caseId,
        date: date,
        token: token,
      },
    });

    console.log("Billing entries retrieved successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const createBillingEntry = async (formData) => {
  try {
    const token = localStorage.getItem("access_token");

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',  // Ensure correct header for file upload
      },
      params: new URLSearchParams({
        case_id: formData.get('case_id'),
        date: formData.get('date'),
        description: formData.get('description'),
        amount: formData.get('amount'),
        token: token,
      }),
    };

    const response = await apiClient.post('/billing/create', formData, config);
    console.log("Billing entry created successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const updateBillingEntry = async (formData, proofs) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.put(`/billing/${formData.caseId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log("Billing entry updated successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};

export const deleteBillingEntry = async (caseId) => {
  try {
    const token = localStorage.getItem("access_token"); // Retrieve the token from local storage

    const response = await apiClient.delete(`/billing/${caseId}`, {
      params: { token }, // Include token as a query parameter
    });

    console.log("Billing entry deleted successfully:", response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.error('Validation Error:', error.response.data);
    } else {
      console.error('An error occurred:', error.message);
    }
    throw error;
  }
};