import React, { useState, useEffect } from "react";
import { Button, Form, ListGroup, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCalendar } from "react-icons/ai";
import trash from "../assets/trash.svg";
import {
  getTodosByCaseId,
  createTodo,
  updateTodo,
  deleteTodo,
  updateCase
} from "../services/api-service";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import Toastify from "toastify-js";
import { useNavigate } from 'react-router-dom';

function Todo({ task, updateTask, updateTodos }) {
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [newTodo, setNewTodo] = useState("");
  const [created_by, setCreatedBy] = useState(task.created_by);
  const [last_edited_by, setLastEditedBy] = useState(task.last_edited_by);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [todoToDelete, setTodoToDelete] = useState(null);
  const [newTodoDate, setNewTodoDate] = useState(task.due_date ? new Date(task.due_date) : null);
  const navigate = useNavigate();

  useEffect(() => {
    if (task && task.case_id) {
      const fetchTodos = async () => {
        try {
          const fetchedTodos = await getTodosByCaseId(task.case_id);
          setTodos(fetchedTodos.data);
          setLoading(false); 
        } catch (error) {
          setLoading(false);
        }
      };

      fetchTodos();
    }
  }, [task]);

  const handleAddTodo = async () => {
    if (!newTodoDate) {
      console.log("Please select a due date for the todo.");
      return;
    }
    if (newTodo.trim() !== "") {
      try {
        const response = await createTodo({
          case_id: task.case_id,
          description: newTodo,
          due_date: newTodoDate.toISOString(),
          status: "active",
        });

        const createdTodo = response.data;
        const updatedTodos = [...todos, createdTodo];
        updateTodos(updatedTodos);
        setTodos((prevTodos) => [...prevTodos, createdTodo]);
        setNewTodo("");
        setNewTodoDate(null);
        setShowAddTodo(false);
      } catch (error) {
        console.error("Failed to create todo:", error);
      }
    }
  };

  const handleDateChange = async (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    setNewTodoDate(new Date(`${year}-${month}-${day}`));
    const updatedCase = {
      client_name: task.client_name,
      it_username: task.it_username,
      password: task.password,
      mobile_number: task.mobile_number,
      email_id: task.email_id,
      ca_name: task.ca_name,
      ca_number: task.ca_number,
      ca_email_id: task.ca_email_id,
      case_description: task.case_description,
      case_status: task.case_status, 
      pan: task.pan,
      din: task.din,
      scrunity_by: task.scrunity_by,
      under_section: task.under_section,
      due_date: formattedDate, 
    };
  
    try {
      const response = await updateCase(task.case_id, updatedCase); 
      Toastify({
        text: "Due date updated successfully!",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
      updateTask({
        _id: task._id,
        client_name: task.client_name,
        it_username: task.it_username,
        password: task.password,
        mobile_number: task.mobile_number,
        email_id: task.email_id,
        ca_name: task.ca_name,
        ca_number: task.ca_number,
        ca_email_id: task.ca_email_id,
        case_description: task.case_description,
        case_status: task.case_status, 
        pan: task.pan,
        din: task.din,
        scrunity_by: task.scrunity_by,
        under_section: task.under_section,
        due_date: formattedDate, 
      }); 
    } catch (error) {
      console.error("Error updating due date:", error);
    }
  };

  const handleDeleteTodo = (id) => {
    setTodoToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteTodo = async () => {
    try {
      await deleteTodo(task.case_id, todoToDelete);
      setTodos(todos.filter((todo) => todo.todo_id !== todoToDelete));
      updateTodos(todos.filter((todo) => todo.todo_id !== todoToDelete));
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Failed to delete todo:", error);
    }
  };

  const handleToggleTodo = async (id) => {
    const todoToUpdate = todos.find((todo) => todo.todo_id === id);
    if (todoToUpdate) {
      try {
        const updatedTodo = {
          ...todoToUpdate,
          done: !todoToUpdate.done,
          status: !todoToUpdate.done ? "completed" : "active", // Update status based on completion
        };
        await updateTodo(task.case_id, id, updatedTodo);
        setTodos(todos.map((todo) => (todo.todo_id === id ? updatedTodo : todo)));
        const updatedTodosList = todos.map((todo) => 
        todo.todo_id === id ? updatedTodo : todo
      );
        updateTodos(updatedTodosList);
      } catch (error) {
        console.error("Failed to update todo:", error);
      }
    }
  };

  const activeTodos = todos.filter((todo) => todo.status === "active");
  const completedTodos = todos.filter((todo) => todo.status === "completed");

  return (
    <>
      <div className="d-flex flex-column mb-2">
        <div className="d-flex mb-1 gap-2 align-items-center justify-content-start">
          <h5 style={{ fontSize: '1.1rem' }} className="mb-0">
            Due Date:
          </h5>
          <div className="d-flex align-items-center justify-content-start" style={{ position: 'relative' }}>
            <span>{newTodoDate ? newTodoDate.toLocaleDateString() : 'N/A'}</span>
            <AiOutlineCalendar
              size={24}
              onClick={() => setShowDatePicker(!showDatePicker)}
              style={{ cursor: 'pointer', marginLeft: '8px' }}
            />
            {showDatePicker && (
              <div style={{ position: 'absolute', top: '100%', zIndex: 1000 }}>
                <DatePicker
                  selected={newTodoDate}
                  onChange={(date) => {
                    handleDateChange(date);
                    setShowDatePicker(false);
                  }}
                  dateFormat="MM/dd/yyyy"
                  className="form-control mb-2"
                  inline
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center justify-content-start">
          <small className="small-font">
            Created By:{" "}
            {created_by ? created_by : "N/A"}
          </small>
          <small className="small-font">
            Last Edited By:{" "}
            {last_edited_by ? last_edited_by : "N/A"}
          </small>
        </div>
      </div>

      <div className="sidebar-todos">
        <div className="d-flex justify-content-between align-items-center">
          <h5 style={{ fontSize: "1.1rem" }}>Todos</h5>
          <Button
            variant="link"
            className="d-flex align-items-center m-0 p-0"
            onClick={() => setShowAddTodo(true)}
          >
            <AiOutlinePlus size={20} className="mr-2 text-dark" />
          </Button>
        </div>

        {loading ? ( 
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <>
            <ListGroup>
              {activeTodos.map((todo) => (
                <ListGroup.Item
                  key={todo.todo_id}
                  className="d-flex justify-content-between align-items-center small-font p-2"
                >
                  <div>
                    <Form.Check
                      type="checkbox"
                      label={todo.description}
                      checked={todo.done}
                      onChange={() => handleToggleTodo(todo.todo_id)}
                    />
                    {todo.due_date && (
                      <div className="text-muted small small-font">
                        Due: {new Date(todo.due_date).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => handleDeleteTodo(todo.todo_id)}
                    className="d-flex justify-content-end"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={trash} className="w-25" alt="trash-icon" />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <h5 className="mt-4" style={{ fontSize: "1.1rem" }}>
              Completed Todos
            </h5>
            <ListGroup>
              {completedTodos.map((todo) => (
                <ListGroup.Item
                  key={todo.todo_id}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <div className="small-font">{todo.description}</div>

                    {todo.due_date && (
                      <div className="text-muted small small-font">
                        Due: {new Date(todo.due_date).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => handleDeleteTodo(todo.todo_id)}
                    className="d-flex justify-content-end small-font"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={trash} className="w-25" alt="trash-icon" />
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </div>
      {showAddTodo && (
        <div className="modal-overlay">
          <div className="modal-content-inner">
            <h5>Add New Todo</h5>
            <Form>
              <Form.Group controlId="formNewTodoContent">
                <Form.Control
                  type="text"
                  placeholder="Enter todo description"
                  value={newTodo}
                  onChange={(e) => setNewTodo(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formNewTodoDate" className="mt-3">
                <DatePicker
                  selected={newTodoDate}
                  onChange={(date) => setNewTodoDate(date)}
                  className="form-control w-100"
                  placeholderText="Select due date"
                />
              </Form.Group>
                
                <Button variant="primary"   style={{ marginRight: "0.5rem" }} onClick={handleAddTodo} className="mt-3">
                Add Todo
              </Button>
                <Button variant="secondary"  className="mt-3 ml-2" onClick={() => setShowAddTodo(false)}>
                Cancel
              </Button>
            </Form>
          </div>
        </div>
      )}

      <ConfirmDeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={confirmDeleteTodo}
      />
    </>
  );
}

export default Todo;
