import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import Advocate from "../assets/adv_hand_img.png";
import Logo from "../assets/VisumAI_Dark_Transparent.png";
import { login } from "../services/api-service";
import Toastify from 'toastify-js';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSession } from '../services/SessionProvider';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const { setIsSessionExpired } = useSession();
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSessionExpired = () => {
    setIsSessionExpired(true);
    localStorage.setItem("sessionExpired", "true");
    console.log("Executing handleSessionExpired.");
  
    Toastify({
      text: "Your session has expired. Please log in again.",
      duration: 3000,
      close: true,
      gravity: "top",
      position: "right",
      backgroundColor: "#f44336",
      stopOnFocus: true,
    }).showToast();
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
  
    if (!email || !password) {
      Toastify({
        text: "Please fill in all fields correctly.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
        stopOnFocus: true
      }).showToast();

      return;
    }
  
    setLoading(true);
    try {
      const response = await login(formData.email, formData.password, handleSessionExpired);
      console.log("Login successful:", response.access_token);
      localStorage.setItem("access_token", response.access_token);
      setSuccess(true);
      setLoading(false);
      setIsSessionExpired(false);
      navigate("/homepage");
    } catch (error) {
      Toastify({
        text: "Login failed",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
        stopOnFocus: true
      }).showToast();

      setLoading(false);
      console.error("Login error:", error);
    }
  };
  


  // const handleSessionExpired = async () => {
  //   const token = localStorage.getItem('access_token');
  //     Toastify({
  //       text: "Session Expired, Please login.",
  //       duration: 3000,
  //       close: true,
  //       gravity: "top", 
  //       position: "right", 
  //       backgroundColor: "#f44336",
  //       stopOnFocus: true, 
  //     }).showToast();
      
  //     handleSessionExpiration(); 
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const { email, password } = formData;
  
  //   if (!email || !password) {
  //     Toastify({
  //       text: "Please fill in all fields correctly.",
  //       duration: 3000, // Duration in milliseconds
  //       close: true, 
  //       gravity: "top", 
  //       position: "right", 
  //       backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)", 
  //       stopOnFocus: true 
  //     }).showToast();
    
  //     return;
  //   }
  
  //   setLoading(true);
  //   try {
  //     const response = await login(formData.email, formData.password, handleSessionExpired);
  //     console.log("Login successful:", response.access_token);
  //     localStorage.setItem("access_token", response.access_token);
  //     setSuccess(true);
  //     setLoading(false);
  
  //     navigate("/homepage");
  //   } catch (error) {
  //     Toastify({
  //       text: "Login failed",
  //       duration: 3000,
  //       close: true,
  //       gravity: "top",
  //       position: "right",
  //       backgroundColor: "linear-gradient(to right, #FF6347, #FF4500)",
  //       stopOnFocus: true
  //     }).showToast();
  
  //     setLoading(false);
  //     console.error("Login error:", error);
  //   }
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="login template d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "var(--background-color)" }}
    >
      <div
        className="d-flex border overflow-hidden w-100 vh-100"
        style={{
          borderColor: "var(--img)",
        }}
      >
        <div className="w-50 d-flex align-items-center justify-content-center">
          <img src={Advocate} className="m-auto" width={'450px'} alt="Advocate" />
        </div>
        <div
          className="w-50 bg-light"
        >
          <form
            className="h-100 d-flex flex-column p-lg-5 p-md-3 p-sm-2 justify-content-center"
            onSubmit={handleSubmit}
          >
            <div>
              <img src={Logo} className="d-block m-auto" width={300} alt="Advocate" />
              <h3
                className="d-flex mb-1 align-items-center justify-content-start font-montserrat fs-2"
                style={{ fontWeight: "700" }}
              >
                Login
              </h3>
              <p
                className="mb-5"
                style={{ fontWeight: "500" }}
              >
                Please login to continue
              </p>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className="lined-input"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="position-relative">
  <input
    type={showPassword ? "text" : "password"} // Toggle between text and password
    name="password"
    placeholder="Password"
    className="lined-input mb-1"
    value={formData.password}
    onChange={handleChange}
    style={{ paddingRight: "40px" }} // Add padding to the input to make space for the icon
  />
  <span
    className="position-absolute"
    style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }} // Center icon vertically
    onClick={togglePasswordVisibility}
  >
    {showPassword ? <FaEye /> :  <FaEyeSlash />} {/* Icon changes */}
  </span>
</div>

              <p className="text-end">
                  <Link to="/" className="text-decoration-none text-end">
                    Forgot password?
                  </Link>
                </p>
            </div>
            <div>
              <div className="d-grid">
                {success && (
                  <div className="alert text-success p-0 text-end mb-1">
                    Login successful!
                  </div>
                )}
                <button
                  className="btn text-primary text-light"
                  disabled={loading}
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </div>
              <div className="text-right d-flex justify-content-end mt-2">
                <p>
                  No account?{" "}
                  <Link to="/signup" className="text-decoration-none">
                    Create one
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
