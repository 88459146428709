import React from 'react';
import { useNavigate } from 'react-router-dom';

const SessionExpiredModal = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/');
  };

  return (
    <div className="modal-overlay d-flex align-items-center justify-content-center position-fixed w-100 h-100" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', top: 0, left: 0, zIndex: 9999 }}>
      <div className="modal-content bg-white p-4 rounded text-center" style={{ maxWidth: '400px', zIndex: '999' }}>
        <h2 className="mb-3">Session Expired!</h2>
        <p className="mb-4">Please log in again to continue.</p>
        <button onClick={handleLogin} className="btn btn-primary">Login</button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
