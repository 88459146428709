import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import { FiClipboard } from "react-icons/fi";
import "react-quill/dist/quill.snow.css";
import {
  updateCase,
  uploadDocuments,
  getUploadDocuments,
  createStatement,
  getStatement,
  getUserDetails,
  getAIResponse,
  updateStatement,
  updateAppealStatement,
  getAppeals,
  createAppeal,
  deleteCaseDetails,
  createNote,
  getNotes,
  updateNote,
  checkTokenExpiry,
} from "../services/api-service";
import Todo from "../components/Todo";
import CopyButton from "./CopyButton";
import Toastify from "toastify-js";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import BillingDetails from "./BillingDetails";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PdfModal from "./PdfModal";
import SessionExpiredModal from './SessionExpiredModal';

function EditTaskModal({ show, onHide, task, updateTask, updateTodos, onDelete }) {
  const [statementOfFactsContent, setStatementOfFactsContent] = useState("");
  const [groundOfAppealContent, setGroundOfAppealContent] = useState("");
  const [notesContent, setNotesContent] = useState("");
  const [isStatementOfFactsSaved, setIsStatementOfFactsSaved] = useState(false);
  const [isGroundOfAppealSaved, setIsGroundOfAppealSaved] = useState(false);
  const [isNotesSaved, setIsNotesSaved] = useState(false);
  const [taskDate, setTaskDate] = useState("");
  const [activeKey, setActiveKey] = useState("0");
  const [aiQuestion, setAiQuestion] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [documentType, setDocumentType] = useState("assessmentOrder");
  const [customDocumentType, setCustomDocumentType] = useState("");
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteAction, setDeleteAction] = useState(() => () => {});
  const fileInputRef = useRef(null);
  const [adminCheckPending, setAdminCheckPending] = useState(false);
  const [statementId, setStatementId] = useState(null);
  const [appealId, setAppealId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingForCaseDetails, setLoadingForCaseDetails] = useState(false);
  const [loadingForSOF, setLoadingForSOF] = useState(false);
  const [loadingForGOA, setLoadingForGOA] = useState(false);
  const [loadingForNotes, setLoadingForNotes] = useState(false);
  const [loadingForUploadDocuments, setLoadingForUploadDocuments] =
    useState(false);
  const [sofApproveStatus, setSofApproveStatus] = useState(null);
  const [goaApproveStatus, setGoaApproveStatus] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [showDocModal, setShowDocModal] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const handleClose = () => {
    onHide();
    setShowModal(false);
  };

  const handleShow = async (action) => {
    setAdminCheckPending(true);
    const isAdmin = await checkIfAdmin();
    if (isAdmin) {
      setDeleteAction(() => action);
      setShowModal(true);
    }
    setAdminCheckPending(false);
  };

  const handleDeleteCase = async () => {
    try {
      if (onDelete) {
        onDelete();
        onHide();
        handleClose();
      } else {
        console.error("onDelete prop is not a function");
      }

      setShowModal(true);
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const handleDelete = async (doc) => {
    const { upload_id, document_ids } = doc;
    try {
      const result = await deleteCaseDetails(
        task.case_id,
        upload_id,
        document_ids
      );
      console.log("Document IDs to be removed:", document_ids);

      const idsToRemove = Array.isArray(document_ids)
        ? document_ids
        : [document_ids];
      setUploadedDocuments(
        (prevDocuments) =>
          prevDocuments.filter((doc) => !idsToRemove.includes(doc.document_id)) // Use idsToRemove here
      );

      const response = fetchUploadedDocuments();
      if ((response.detail = "Case details not found")) {
        setUploadedDocuments("");
      }
      Toastify({
        text: "document deleted successfully!",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
    } catch (error) {
      console.error("Failed to delete case details:", error);
    }
  };

  const checkIfAdmin = async () => {
    const userResponse = await getUserDetails();
    const userRole = userResponse.user_type;

    if (userRole !== "Admin") {
      Toastify({
        text: "Unauthorized: You don't have permission to perform this action.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#f44336",
        stopOnFocus: true,
      }).showToast();

      return false;
    }

    return true;
  };

  const [casePersonnelDetails, setCasePersonnelDetails] = useState({
    clientName: "",
    itUsername: "",
    password: "",
    mobileNumber: "",
    emailId: "",
    caName: "",
    caNumber: "",
    caEmailId: "",
    caseDescription: "",
    caseId: "",
    pan: "",
    din: "",
    scrunity_by: "",
    under_section: "",
    due_date: null,
  });

  const toggleAccordion = (index) => {
    setActiveKey(activeKey === index ? null : index);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    setTaskDate(task.date || "");
    // setAccordionContent(task.accordionContent || {});
    fetchGetStatement("statementOfFacts");
    fetchGetStatement("groundOfAppeal");
    fetchGetNotes();
    fetchUploadedDocuments();
    setCasePersonnelDetails({
      clientName: task.client_name || "",
      itUsername: task.it_username || "",
      password: task.password || "",
      mobileNumber: task.mobile_number || "",
      emailId: task.email_id || "",
      caName: task.ca_name || "",
      caNumber: task.ca_number || "",
      caEmailId: task.ca_email_id || "",
      caseDescription: task.case_description || "",
      caseStatus: task.case_status || "",
      pan: task.pan || "",
      din: task.din || "",
      scrunity_by: task.scrunity_by || "",
      under_section: task.under_section || "",
      due_date: task.due_date || null,
    });
  }, [task]);

  const toggleEdit = async () => {
    if (isEditable) {
      UpdateCaseDetails();
    } else {
      const userResponse = await getUserDetails();
      const userRole = userResponse.user_type;
      if (userRole === "Super-Admin") {
        Toastify({
          text: "Unauthorized: You don't have permission to perform this action.",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "#f44336",
          stopOnFocus: true,
        }).showToast();
        return;
      }
      setIsEditable(true);
    }
  };

  const handleSave = (e) => {
    e.stopPropagation();
    updateTask({
      _id: task._id,
      client_name: casePersonnelDetails.clientName,
      it_username: casePersonnelDetails.itUsername,
      password: casePersonnelDetails.password,
      mobile_number: casePersonnelDetails.mobileNumber,
      email_id: casePersonnelDetails.emailId,
      ca_name: casePersonnelDetails.caName,
      ca_number: casePersonnelDetails.caNumber,
      ca_email_id: casePersonnelDetails.caEmailId,
      case_description: casePersonnelDetails.caseDescription,
      case_status: casePersonnelDetails.caseStatus,
      pan: casePersonnelDetails.pan,
      din: casePersonnelDetails.din,
      scrunity_by: casePersonnelDetails.scrunity_by,
      under_section: casePersonnelDetails.under_section,
      due_date: casePersonnelDetails.due_date,
    });
    onHide();
  };

  const UpdateCaseDetails = async () => {
    setLoadingForCaseDetails(true);
    const updatedCase = {
      client_name: casePersonnelDetails.clientName,
      it_username: casePersonnelDetails.itUsername,
      password: casePersonnelDetails.password,
      mobile_number: casePersonnelDetails.mobileNumber,
      email_id: casePersonnelDetails.emailId,
      ca_name: casePersonnelDetails.caName,
      ca_number: casePersonnelDetails.caNumber,
      ca_email_id: casePersonnelDetails.caEmailId,
      case_description: casePersonnelDetails.caseDescription,
      case_status: casePersonnelDetails.caseStatus,
      pan: casePersonnelDetails.pan,
      din: casePersonnelDetails.din,
      scrunity_by: casePersonnelDetails.scrunity_by,
      under_section: casePersonnelDetails.under_section,
      due_date: casePersonnelDetails.due_date,
    };
    try {
      const response = await updateCase(task.case_id, updatedCase);
      Toastify({
        text: "updated successfully!",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
      setLoadingForCaseDetails(false);
      updateTask({
        _id: task._id,
        client_name: casePersonnelDetails.clientName,
        it_username: casePersonnelDetails.itUsername,
        password: casePersonnelDetails.password,
        mobile_number: casePersonnelDetails.mobileNumber,
        email_id: casePersonnelDetails.emailId,
        ca_name: casePersonnelDetails.caName,
        ca_number: casePersonnelDetails.caNumber,
        ca_email_id: casePersonnelDetails.caEmailId,
        case_description: casePersonnelDetails.caseDescription,
        case_status: casePersonnelDetails.caseStatus,
        pan: casePersonnelDetails.pan,
        din: casePersonnelDetails.din,
        scrunity_by: casePersonnelDetails.scrunity_by,
        under_section: casePersonnelDetails.under_section,
        due_date: casePersonnelDetails.due_date,
      });
      setIsEditable(false);
    } catch (error) {
      console.error("Error updating case:", error);
      setLoadingForCaseDetails(false);
    }
  };

  const fetchUploadedDocuments = async () => {
    setUploadedDocuments([]);

    try {
      const data = await getUploadDocuments(task.case_id);
      if (data && data.data && Array.isArray(data.data)) {
        setUploadedDocuments(data.data); // Set the documents from the response
      }
    } catch (error) {
      console.error("Error getting case:", error);
    }
  };

  const fetchGetStatement = async (type) => {
    let index = -1;

    if (type === "statementOfFacts") {
      index = 0;
    } else if (type === "groundOfAppeal") {
      index = 1;
    } else {
      console.error("Invalid type");
      return;
    }

    try {
      if (type === "statementOfFacts") {
        const response = await getStatement(task.case_id);
        setStatementOfFactsContent(response.data.statement_content);
        setSofApproveStatus(response.data.approval_status);
        if (response.data.statement_id) {
          setStatementId(response.data.statement_id);
          setIsStatementOfFactsSaved(true);
        }
      } else if (type === "groundOfAppeal") {
        const response = await getAppeals(task.case_id);
        setGroundOfAppealContent(response.data.appeal_content);
        setGoaApproveStatus(response.data.approval_status);
        if (response.data.appeal_id) {
          setAppealId(response.data.appeal_id);
          setIsGroundOfAppealSaved(true);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const fetchGetNotes = async () => {
    try {
      const response = await getNotes(task.case_id);
      setNotesContent(response[0].note_content);
      setIsNotesSaved(true);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleCreateStatement = async (type) => {
    console.log(type, "type");
    console.log(groundOfAppealContent, "groundOfAppealContent");
    const content =
      type === "statementOfFacts"
        ? statementOfFactsContent
        : groundOfAppealContent;
    try {
      if (type === "statementOfFacts" && isStatementOfFactsSaved) {
        setLoadingForSOF(true);
        const userResponse = await getUserDetails();
        const editedBy = userResponse.username;
        await updateStatement({
          case_id: task.case_id,
          statement_id: statementId,
          document_content: content,
          edited_by: editedBy,
          approval_status: "pending",
          edit_date: new Date().toISOString(),
          approval_date: new Date().toISOString(),
        });
        setLoadingForSOF(false);
        console.log(`Statement of Facts updated successfully`);
      } else if (type === "groundOfAppeal" && isGroundOfAppealSaved) {
        setLoadingForGOA(true);
        const userResponse = await getUserDetails();
        const editedBy = userResponse.username;
        await updateAppealStatement({
          case_id: task.case_id,
          appeal_id: appealId,
          document_content: content,
          edited_by: editedBy,
          approval_status: "pending",
          edit_date: new Date().toISOString(),
          approval_date: new Date().toISOString(),
        });
        setLoadingForGOA(false);
        console.log(`Ground of Appeal updated successfully`);
      } else {
        if (type === "statementOfFacts") {
          setLoadingForSOF(true);
          const userResponse = await getUserDetails();
          const editedBy = userResponse.username;
          await createStatement({
            case_id: task.case_id,
            document_content: content,
            edited_by: editedBy,
            approval_status: "pending",
            edit_date: new Date().toISOString(),
            approval_date: new Date().toISOString(),
          });
          setLoadingForSOF(false);
          setIsStatementOfFactsSaved(true);
        } else if (type === "groundOfAppeal") {
          setLoadingForGOA(true);
          const userResponse = await getUserDetails();
          const editedBy = userResponse.username;
          await createAppeal({
            case_id: task.case_id,
            document_content: content,
            edited_by: editedBy,
            approval_status: "pending",
            edit_date: new Date().toISOString(),
            approval_date: new Date().toISOString(),
          });
          setIsGroundOfAppealSaved(true);
          setLoadingForGOA(false);
        }
      }
      Toastify({
        text: `${
          type === "statementOfFacts"
            ? "Statement of Facts"
            : "Ground of Appeal"
        } ${
          isStatementOfFactsSaved || isGroundOfAppealSaved
            ? "updated"
            : "created"
        } successfully!`,
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleCreateNotes = async () => {
    setLoadingForNotes(true);
    try {
      if (isNotesSaved) {
        await updateNote(task.case_id, {
          case_id: task.case_id,
          note_content: notesContent,
          edit_date: new Date().toISOString(),
        });
        setLoadingForNotes(false);
        console.log(`Notes Updated successfully`);
        Toastify({
          text: "Notes Updated successfully!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      } else {
        await createNote({
          case_id: task.case_id,
          note_content: notesContent,
          edit_date: new Date().toISOString(),
        });
        setLoadingForNotes(false);
        console.log(`Notes Created successfully`);
        Toastify({
          text: "Notes Created successfully!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      }
    } catch (error) {
      console.error(`Error handling:`, error);
    }
  };

  // const handleDeleteStatement = async (type) => {
  //   try {
  //     await deleteStatement({
  //       case_id: task.case_id,
  //       statement_id: task.statement_id,
  //     });
  //     if (type === "0") {
  //       setStatementOfFactsContent("");
  //     } else if (type === "1") {
  //       setGroundOfAppealContent("");
  //     }
  //     console.log(`${type} deleted successfully`);
  //     if (type === "0") {
  //       setStatementOfFactsContent("");
  //       setIsStatementOfFactsSaved(false);
  //     } else if (type === "1") {
  //       setGroundOfAppealContent("");
  //       setIsGroundOfAppealSaved(false);
  //     }
  //   } catch (error) {
  //     console.error(`Error deleting ${type}:`, error);
  //   }
  // };

  const handleAccordionChange = (type, content) => {
    if (content !== groundOfAppealContent && type === "0") {
      setGroundOfAppealContent(content);
    } else if (type === "1") {
      setStatementOfFactsContent(content);
    }
  };

  const handleNotesChange = (content) => {
    setNotesContent(content);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        Toastify({
          text: "Copied to clipboard!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleAIQuestionSubmit = async () => {
    setLoading(true);
    try {
      const response = await getAIResponse(task.case_id, aiQuestion);
      setAiResponse(response.llm_res);
    } catch (error) {
      console.error("Failed to fetch AI response:", error);
      setAiResponse(
        "Sorry, we couldn't get a response from the AI. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentChange = (e) => {
    setDocumentFiles([...e.target.files]);
  };

  const handleDocumentTypeChange = (e) => {
    const { value } = e.target; // Use 'e' instead of 'event'
    setDocumentType(value);

    // Clear custom document type if a different option is selected
    if (value !== "others") {
      setCustomDocumentType("");
    }
  };

  const handleDocumentNameChange = (e) => {
    setDocumentName(e.target.value);
  };

  const handleCasePersonnelChange = (e) => {
    const { name, value } = e.target;
    setCasePersonnelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    setLoadingForUploadDocuments(true);
    try {
      const finalDocumentType =
        documentType === "others" ? customDocumentType : documentType;
      await uploadDocuments(
        task.case_id,
        documentName,
        finalDocumentType,
        documentFiles
      );
      fetchUploadedDocuments();
      Toastify({
        text: "Documents uploaded successfully",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
        stopOnFocus: true,
      }).showToast();
      setLoadingForUploadDocuments(false);
      setDocumentName("");
      setDocumentType("");
      setDocumentFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      console.log("Documents uploaded successfully");
    } catch (error) {
      console.error("Error uploading documents:", error);
      setLoadingForUploadDocuments(false);
    }
  };

  const handleShowDoc = (url) => {
    const NewUrl = "https://testinglegalvisum.visumai.in/bucket/legalvisum/" + url;
    setSelectedPdfUrl(NewUrl);
    setShowDocModal(true);
  };

  const handleCloseDoc = () => {
    setShowDocModal(false);
    setSelectedPdfUrl("");
  };

  const handleApprove = async (id, type) => {
    const userResponse = await getUserDetails();
    const userRole = userResponse.user_type;
    const editedBy = userResponse.username;

    if (userRole !== "Super-Admin" && userRole !== "Admin") {
      Toastify({
        text: "Unauthorized: You don't have permission to perform this action.",
        duration: 3000,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#f44336",
        stopOnFocus: true,
      }).showToast();

      return;
    }

    try {
      if (type === "statementOfFacts") {
        await updateStatement({
          case_id: task.case_id,
          statement_id: statementId,
          document_content: statementOfFactsContent,
          edited_by: editedBy,
          approval_status: "approved",
          edit_date: new Date().toISOString(),
          approval_date: new Date().toISOString(),
        });
        const response = fetchGetStatement("statementOfFacts");
        setSofApproveStatus(response.data.approval_status);
        console.log(`Statement of Facts approved by admin.`);
        Toastify({
          text: "Statement of Facts approved successfully!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      } else if (type === "groundOfAppeal") {
        await updateAppealStatement({
          case_id: task.case_id,
          appeal_id: appealId,
          document_content: groundOfAppealContent,
          edited_by: editedBy,
          approval_status: "approved",
          edit_date: new Date().toISOString(),
          approval_date: new Date().toISOString(),
        });
        const response = fetchGetStatement("groundOfAppeal");
        setGoaApproveStatus(response.data.approval_status);
        console.log(`Ground of Appeal approved by admin.`);
        Toastify({
          text: "Ground of Appeal approved successfully!",
          duration: 3000,
          close: true,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #32CD32, #008000)",
          stopOnFocus: true,
        }).showToast();
      } else {
        console.error("Invalid document type.");
      }
    } catch (error) {
      console.error(`Error approving ${type}:`, error);
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date
      ? date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
    handleCasePersonnelChange({
      target: {
        name: "due_date",
        value: formattedDate,
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        dialogClassName="modal-75w"
        className="overflow-scroll"
      >
        <Modal.Header
          closeButton
          onClick={(e) => {
            e.stopPropagation();
            onHide(e);
          }}
        >
          <Modal.Title>Edit Case</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-scrollable-content">
          <Row className="mt-3">
            <Col md={8}>
              <div className="accordion" id="accordionExample">
                {[
                  "Case details",
                  "Upload documents",
                  "Statement of facts",
                  "Ground of appeal",
                  "Notes",
                  "AI",
                ].map((title, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className={`accordion-button ${
                          activeKey !== index.toString() ? "collapsed" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded={activeKey === index.toString()}
                        aria-controls={`collapse${index}`}
                        onClick={() => toggleAccordion(index.toString())}
                      >
                        {title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${
                        activeKey === index.toString() ? "show" : ""
                      }`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {title === "Case details" ? (
                          <>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="formcaseDescription">
                                  <Form.Label className="mb-0 small-font">
                                    Case Description
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="caseDescription"
                                      value={
                                        casePersonnelDetails.caseDescription
                                      }
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                      style={{ paddingRight: "1rem" }} // Add padding to the right to make space for the button
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.caseDescription
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Form.Group controlId="formClientName">
                                  <Form.Label className="mb-0 small-font">
                                    Client Name
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="clientName"
                                      value={casePersonnelDetails.clientName}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.clientName
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="formItUsername">
                                  <Form.Label className="mb-0 small-font">
                                    IT Username
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="itUsername"
                                      className="small-font"
                                      value={casePersonnelDetails.itUsername}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.itUsername
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="formPassword">
                                  <Form.Label className="mb-0 small-font">
                                    Password
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type={showPassword ? "text" : "password"}
                                      name="password"
                                      value={casePersonnelDetails.password}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <span
                                      onClick={togglePasswordVisibility}
                                      style={{
                                        position: "absolute",
                                        right: "17%",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {showPassword ? (
                                        <FaEye />
                                      ) : (
                                        <FaEyeSlash />
                                      )}
                                    </span>
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.password}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <Form.Group controlId="formMobileNumber">
                                  <Form.Label className="mb-0 small-font">
                                    Mobile Number
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="mobileNumber"
                                      value={casePersonnelDetails.mobileNumber}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.mobileNumber
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="formEmailId">
                                  <Form.Label className="mb-0 small-font">
                                    Email ID
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="email"
                                      name="emailId"
                                      value={casePersonnelDetails.emailId}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.emailId}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="formCaName">
                                  <Form.Label className="mb-0 small-font">
                                    CA Name
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="caName"
                                      value={casePersonnelDetails.caName}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.caName}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <Form.Group controlId="formCaNumber">
                                  <Form.Label className="mb-0 small-font">
                                    CA Number
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="caNumber"
                                      value={casePersonnelDetails.caNumber}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.caNumber}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="formCaEmailId">
                                  <Form.Label className="mb-0 small-font">
                                    CA Email ID
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="email"
                                      name="caEmailId"
                                      value={casePersonnelDetails.caEmailId}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.caEmailId
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="under_section">
                                  <Form.Label className="mb-0 small-font">
                                    Under section
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="under_section"
                                      value={casePersonnelDetails.under_section}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.under_section
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <Form.Group controlId="pan">
                                  <Form.Label className="mb-0 small-font">
                                    PAN
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="pan"
                                      value={casePersonnelDetails.pan}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.pan}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="din">
                                  <Form.Label className="mb-0 small-font">
                                    DIN
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="din"
                                      value={casePersonnelDetails.din}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={casePersonnelDetails.din}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md={4}>
                                <Form.Group controlId="scrunity_by">
                                  <Form.Label className="mb-0 small-font">
                                    Scrunity By
                                  </Form.Label>
                                  <div style={{ position: "relative" }}>
                                    <Form.Control
                                      type="text"
                                      name="scrunity_by"
                                      value={casePersonnelDetails.scrunity_by}
                                      onChange={handleCasePersonnelChange}
                                      disabled={!isEditable}
                                      className="small-font"
                                    />
                                    <CopyButton
                                      textToCopy={
                                        casePersonnelDetails.scrunity_by
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <Row>
                              <Col
                                md={4}
                                className="d-flex align-items-end justify-content-between"
                              >
                                <Button
                                  variant="primary"
                                  style={{
                                    backgroundColor: "var(--secondary-color)",
                                    width: "5rem",
                                  }}
                                  className="small-font mt-2"
                                  onClick={toggleEdit}
                                >
                                  {loadingForCaseDetails ? (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  ) : isEditable ? (
                                    "Save"
                                  ) : (
                                    "Edit"
                                  )}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        ) : title === "Upload documents" ? (
                          <>
                            {/* Display uploaded documents by default */}
                            <ListGroup className="mb-3">
                              {uploadedDocuments.length > 0 ? (
                                uploadedDocuments.map((doc, index) => (
                                  <ListGroup.Item
                                    key={index}
                                    className="d-flex justify-content-between align-items-center p-2"
                                  >
                                    <span className="small-font">
                                      {doc.document_name} | {doc.document_type}
                                    </span>
                                    <div>
                                      <a
                                        className="small-font text-danger me-2"
                                        onClick={() =>
                                          handleShow(
                                            () => handleDelete(doc),
                                            "Confirm Delete document",
                                            "Are you sure you want to delete this document?"
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        Delete
                                      </a>
                                      {doc.document_paths.length > 0 && (
                                        <span
                                          onClick={() =>
                                            handleShowDoc(doc.document_paths[0])
                                          }
                                          className="small-font"
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          View Doc
                                        </span>
                                      )}

                                      <PdfModal
                                        show={showDocModal}
                                        handleClose={handleCloseDoc}
                                        pdfUrl={selectedPdfUrl}
                                      />
                                    </div>
                                  </ListGroup.Item>
                                ))
                              ) : (
                                <ListGroup.Item className="small-font p-2">
                                  No documents uploaded yet.
                                </ListGroup.Item>
                              )}
                            </ListGroup>

                            {/* Button to show the upload form */}
                            <Button
                              variant="primary"
                              style={{
                                backgroundColor: "var(--secondary-color)",
                              }}
                              className="mb-3 small-font"
                              onClick={() => setShowUploadForm(!showUploadForm)}
                            >
                              {showUploadForm
                                ? "Cancel Upload"
                                : "Upload Documents"}
                            </Button>

                            {/* Conditionally render the upload form */}
                            {showUploadForm && (
      <>
        <Form.Group>
          <Form.Label className="small-font fw-bold mb-1">
            Document Type
          </Form.Label>
          <div>
            <Form.Check
              type="radio"
              label="Assessment Order"
              value="assessmentOrder"
              className="small-font"
                                      checked={
                                        documentType === "assessmentOrder"
                                      }
              onChange={handleDocumentTypeChange}
            />
            <Form.Check
              type="radio"
              label="Financial Statement"
              value="financialStatement"
              className="small-font"
                                      checked={
                                        documentType === "financialStatement"
                                      }
              onChange={handleDocumentTypeChange}
            />
            <div className="d-flex align-items-center gap-2">
              <Form.Check
                type="radio"
                label="Others"
                value="others"
                className="small-font"
                checked={documentType === "others"}
                onChange={handleDocumentTypeChange}
              />
              {documentType === "others" && (
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={customDocumentType}
                                            onChange={(e) =>
                                              setCustomDocumentType(
                                                e.target.value
                                              )
                                            }
                    placeholder="Specify Document Type:"
                    className="small-font p-1"
                  />
                </div>
              )}
            </div>
          </div>
        </Form.Group>

                                <Form.Group
                                  controlId="formOtherDocument"
                                  className="mb-1"
                                >
          <Form.Label className="small-font fw-bold">
            Specify Document Name
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter document Name"
            value={documentName}
            className="small-font"
            onChange={handleDocumentNameChange}
          />
        </Form.Group>

        <div className="d-flex align-items-end gap-3">
          <Form.Group controlId="formFileUpload">
            <Form.Label className="small-font mb-1 fw-bold">
              Upload Documents
            </Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={handleDocumentChange}
              ref={fileInputRef}
              className="small-font"
              accept="application/pdf" // Restrict file picker to PDF
            />
          </Form.Group>
          <div>
            <Button
              variant="primary"
              style={{
                                        backgroundColor:
                                          "var(--secondary-color)",
                width: "5rem",
              }}
              className=" small-font"
              onClick={handleSaveClick}
              disabled={loadingForUploadDocuments}
            >
              {loadingForUploadDocuments ? (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </>
    )}
                          </>
                        ) : title === "AI" ? (
                          <>
                            <Form.Group controlId="formAiQuestion">
                              <Form.Control
                                type="text"
                                placeholder="Ask AI"
                                value={aiQuestion}
                                onChange={(e) => setAiQuestion(e.target.value)}
                                className="small-font"
                              />
                            </Form.Group>
                            <Button
                              variant="primary"
                              className="mt-2 small-font"
                              onClick={handleAIQuestionSubmit}
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                width: "10rem",
                              }}
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />{" "}
                                  Loading...
                                </>
                              ) : (
                                "Get AI Response"
                              )}
                            </Button>
                            {aiResponse && (
                              <div className="mt-3">
                                <h6 className="small-font fw-bold">
                                  AI Response:
                                </h6>
                                <p className="small-font">{aiResponse}</p>
                              </div>
                            )}
                          </>
                        ) : title === "Statement of facts" ? (
                          <>
                            <ReactQuill
                              theme="snow"
                              value={statementOfFactsContent}
                              onChange={(content) =>
                                handleAccordionChange("1", content)
                              }
                              className="w-100"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                copyToClipboard(statementOfFactsContent)
                              }
                              className="mt-2 border-0 p-1"
                              style={{ marginRight: "0.8rem" }}
                            >
                              <FiClipboard />
                            </Button>
                            <Button
                              variant="primary"
                              className="mt-2 small-font"
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                marginRight: ".8rem",
                                width: "5rem",
                              }}
                              onClick={() =>
                                handleCreateStatement("statementOfFacts")
                              }
                            >
                              {loadingForSOF ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  size="sm"
                                />
                              ) : isStatementOfFactsSaved ? (
                                "Update"
                              ) : (
                                "Save"
                              )}
                            </Button>
                            <Button
                              style={{
                                backgroundColor:
                                  sofApproveStatus === "approve"
                                    ? "secondary"
                                    : sofApproveStatus === "pending"
                                    ? "orange"
                                    : "green",
                                color: "white",
                                border: "none",
                              }}
                              className="mt-2 small-font"
                              onClick={() =>
                                handleApprove(1, "statementOfFacts")
                              }
                            >
                              {sofApproveStatus === "approved"
                                ? "Approved"
                                : sofApproveStatus === "pending"
                                ? "Yet to be Approved"
                                : "Approve"}
                            </Button>
                            {/* <Button
                              variant="danger"
                              className="mt-2 small-font"
                              onClick={() => handleShow(handleDeleteStatement('0'), "Confirm Delete Statement", "Are you sure you want to delete this statement?")}
                            >
                              Delete
                            </Button> */}
                          </>
                        ) : title === "Ground of appeal" ? (
                          <>
                            <ReactQuill
                              theme="snow"
                              value={groundOfAppealContent}
                              onChange={(content) =>
                                handleAccordionChange("0", content)
                              }
                              className="w-100"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                copyToClipboard(groundOfAppealContent)
                              }
                              className="mt-2 border-0 p-1"
                              style={{ marginRight: "0.8rem" }}
                            >
                              <FiClipboard />
                            </Button>
                            <Button
                              variant="primary"
                              className="mt-2 small-font"
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                marginRight: ".8rem",
                                width: '5rem'
                              }}
                              onClick={() =>
                                handleCreateStatement("groundOfAppeal")
                              }
                            >
                              {loadingForGOA ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  size="sm"
                                />
                              ) : isGroundOfAppealSaved ? (
                                "Update"
                              ) : (
                                "Save"
                              )}
                            </Button>
                            <Button
                              style={{
                                backgroundColor:
                                  goaApproveStatus === "approve"
                                    ? "secondary"
                                    : goaApproveStatus === "pending"
                                    ? "orange"
                                    : "green",
                                color: "white",
                                border: "none",
                              }}
                              className="mt-2 small-font"
                              onClick={() => handleApprove(1, "groundOfAppeal")}
                            >
                              {goaApproveStatus === "approved"
                                ? "Approved"
                                : goaApproveStatus === "pending"
                                ? "Yet to be Approved"
                                : "Approve"}
                            </Button>
                            {/* <Button
                              variant="danger"
                              className="mt-2 small-font"
                              onClick={() => handleShow(handleDeleteStatement('1'), "Confirm Delete Statement", "Are you sure you want to delete this statement?")}
                              disabled={adminCheckPending}
                            >
                              Delete
                            </Button> */}
                          </>
                        ) : (
                          <>
                            <ReactQuill
                              theme="snow"
                              value={notesContent}
                              onChange={(content) => handleNotesChange(content)}
                              className="w-100"
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={() => copyToClipboard(notesContent)}
                              className="mt-2 border-0 p-1"
                              style={{ marginRight: "0.8rem" }}
                            >
                              <FiClipboard />
                            </Button>
                            <Button
                              variant="primary"
                              className="mt-2 small-font"
                              style={{
                                backgroundColor: "var(--secondary-color)",
                                marginRight: ".8rem",
                                width: '5rem'
                              }}
                              onClick={() => handleCreateNotes()}
                            >
                              {loadingForNotes ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  size="sm"
                                />
                              ) : isNotesSaved ? (
                                "Update"
                              ) : (
                                "Save"
                              )}
                            </Button>

                            {/* <Button
                          variant="danger"
                          className="mt-2 small-font"
                          onClick={() => handleShow(handleDeleteStatement('1'), "Confirm Delete Statement", "Are you sure you want to delete this statement?")}
                          disabled={adminCheckPending}
                        >
                          Delete
                        </Button> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="billing-details mt-4">
                <BillingDetails task={task} />
              </div>
            </Col>
            <Col md={4}>
              <Todo
                task={task}
                updateTask={updateTask}
                updateTodos={updateTodos}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="danger"
            // style={{ backgroundColor: "var(--secondary-color)" }}
            onClick={() =>
              handleShow(
                handleDeleteCase,
                "Confirm Delete Case",
                "Are you sure you want to delete this case?"
              )
            }
            disabled={adminCheckPending}
          >
            Delete
          </Button> */}
          <Button
            variant="primary"
            style={{ backgroundColor: "var(--secondary-color)" }}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {isSessionExpired && <SessionExpiredModal/>}
      <ConfirmDeleteModal
        show={showModal}
        handleClose={handleClose}
        handleDelete={deleteAction}
        title="Confirm Delete"
        body="Are you sure you want to delete this case?"
      />
    </>
  );
}

export default EditTaskModal;
